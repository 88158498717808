import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { QRCodeCanvas } from "qrcode.react";
import { useMemo } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import LoadingIndicator from "../components/loading-indicator";
import { ResponseProject } from "../models/project-query-response";
import { allProjectsState } from "../state/project-state";

export const DisplayQrCode = () => {
  const { id } = useParams();
  const allProjects = useRecoilValueLoadable(allProjectsState);

  const project = useMemo(
    () =>
      allProjects.state === "hasValue"
        ? allProjects.contents.find((x: ResponseProject) => x.id === id)
        : undefined,
    [allProjects, id]
  );

  return (
    <div>
      {project ? (
        <Stack spacing={2} display="flex" alignItems="center">
          <img
            width={200}
            src={require("../assets/logo-letter-only.png")}
            alt="logo"
          />
          <Typography fontFamily="oswald" variant="h5" component="h2">
            Keine Einladung zum Projekt
          </Typography>
          <Typography
            fontFamily="oswald"
            variant="h4"
            fontWeight={600}
            component="h2"
          >
            "{project.name}"
          </Typography>
          <Typography fontFamily="oswald" variant="h5" component="h2">
            erhalten?
          </Typography>
          <div></div>
          <Typography fontFamily="oswald" variant="h5" component="h2">
            Dann scanne diesen QR-Code
          </Typography>
          <div
            style={{
              position: "relative",
            }}
          >
            <QRCodeCanvas
              style={{
                position: "absolute",
                top: 20,
                left: -165
              }}
              size={330}
              value={`https://mach.app/open?code=${project.ownGroup[0].key}`}
            />
            <img
              style={{
                position: "absolute",
                top: 0,
                left: -190,
              }}
              width={380}
              src={require("../assets/qr-code-border.png")}
              alt="border"
            />
          </div>
        </Stack>
      ) : (
        <LoadingIndicator></LoadingIndicator>
      )}
    </div>
  );
};
