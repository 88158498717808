import { AxiosError } from "axios";

export const handleAxiosError = (error: any, errorText: string): string => {
  const axiosError = error as AxiosError;
  if (axiosError.response) {
    if (axiosError.response.status === 401) {
      window.location.reload();
    } else if (axiosError.response.status === 413) {
      throw new Error(
        `${errorText}!\nDatei zu groß!`
      );
    }
    throw new Error(
      `${errorText}!\n${axiosError.response.data?.message ?? axiosError}`
    );
  }
  throw new Error(`${errorText}!\n${error}`);
};
