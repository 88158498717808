import { AxiosInstance } from "axios";
import LogMessage from "../models/log-message";
import { axiosInstance } from "../Shell";

export default class LogService {
    private readonly _axios: AxiosInstance;
    constructor() {
      this._axios = axiosInstance;
    }

    private async sendLog(logMessage: LogMessage): Promise<void> {
        try {
            await this._axios.post("/logs", logMessage);
        } catch (error) {
            console.error("Error while sending crash data", error, logMessage);
        }
    }

    async log(content: unknown): Promise<void> {
        await this.sendLog({ message: JSON.stringify(content), type: "info"});
    }

    async error(content: unknown): Promise<void> {
        await this.sendLog({message: JSON.stringify(content), type: "error"});
    }
}