import React, { useEffect } from "react";
import "./App.css";
import { AppRouter } from "./routing";
import { useRecoilValue } from "recoil";
import { ThemeProvider } from "@emotion/react";
import { themeState } from "./state/theme-state";
import { CssBaseline, Paper } from "@mui/material";
import { ReactComponent as AppStore } from "./assets/app-store-badge.svg";
import { ReactComponent as Playstore } from "./assets/google-play-badge.svg";
import { ReactComponent as Logo } from "./assets/logo.svg";
import { isMobile } from "react-device-detect";
import axios from "axios";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ConfirmProvider } from "material-ui-confirm";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    throw error;
  }
);

function Shell() {
  const theme = useRecoilValue(themeState);

  return (
    <div>
      {isMobile ? (
        <div className="MobileView">
          <Logo className="Logo" style={{ fill: theme.palette.primary.main }} />
          <div className="InfoText">
            <h2>Mach! Web ist leider nicht für Mobilgeräte geeignet</h2>
            <p>Lade dir unsere App aus dem App/Playstore</p>
          </div>
          <div className="DownloadLinks">
            <Playstore
              onClick={() => {
                window.location.href =
                  "https://play.google.com/store/apps/details?id=de.ctechmedia.mach";
              }}
            />
            <AppStore
              onClick={() => {
                window.location.href =
                  "https://apps.apple.com/app/mach/id1542455280";
              }}
            />
          </div>
        </div>
      ) : (
        <ThemeProvider theme={theme}>
          <ConfirmProvider
            defaultOptions={{
              title: "Sind Sie sicher?",
              confirmationText: "Ja",
              cancellationText: "Nein",
            }}
          >
            <Paper
              style={{
                height: "100vh",
                width: "100vw",
              }}
            >
              <CssBaseline />
              <AppRouter></AppRouter>
            </Paper>
          </ConfirmProvider>
        </ThemeProvider>
      )}
    </div>
  );
}

export default Shell;
