import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import { useRecoilState } from "recoil";
import { isDarkThemeState } from "../state/theme-state";
export type ThemeSwitcherProps = {
  label: string;
};
const ThemeSwitcher = (props:ThemeSwitcherProps) => {
    const [isDarkTheme, setTheme] = useRecoilState(isDarkThemeState)
  return <FormControlLabel control={<Switch aria-label="theme-switch" defaultChecked={isDarkTheme} onChange={(event, checked) => {
    setTheme(checked);
}}></Switch>} label={props.label} />;
};

export default ThemeSwitcher;