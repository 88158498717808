import { atom } from "recoil";

export const menuState = atom<boolean>({
  key: "menuState",
  effects: [
    ({ onSet }) => {
      onSet((isExpanded) => {
        localStorage.setItem("menu-expanded", isExpanded ? "true" : "false");
      });
    },
  ],
  default: localStorage.getItem("menu-expanded") === "true",
});
