import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";

import { Avatar, ListItemText, Skeleton, TextField } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import AppLogo from "./app-logo";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import "./project-navigation-bar.css";
import ConfirmationDialog from "./confirmation-dialog";
import ThemeSwitcher from "./theme-switcher";
import { stringAvatar } from "../utils/avatar-utils";
import { useRecoilState, useRecoilValue } from "recoil";
import { menuState } from "../state/menu-state";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "react-oidc-context";
import { themeState } from "../state/theme-state";
import { useNavigate } from "react-router-dom";

export interface ProjectNavigationBarProps {
  showMenu: boolean;
}

const ProjectNavigationBar = (props: ProjectNavigationBarProps) => {
  const [username, setUsername] = useState<string>("undefined");
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();
  const auth = useAuth();
  const theme = useRecoilValue(themeState);

  useEffect(() => {
    setUsername(auth.user?.profile.preferred_username ?? "undefined");
    setEmail(auth.user?.profile.email ?? "");
  }, [auth]);

  const [isMenuExpanded, setMenuState] = useRecoilState(menuState);
  const [, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      color="inherit"
      position="static"
      className="project-navigation-bar-container"
    >
      <div className="menu">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {props.showMenu ? (
              <Box
                sx={{
                  marginX: 2,
                  marginY: "auto",
                }}
                onClick={() => {
                  setMenuState(!isMenuExpanded);
                }}
              >
                <FontAwesomeIcon
                  icon={isMenuExpanded ? faAngleDoubleLeft : faAngleDoubleRight}
                  size="lg"
                ></FontAwesomeIcon>
              </Box>
            ) : null}
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 0, display: "flex" }}
              // eslint-disable-next-line no-restricted-globals
              onClick={() => (location.href = "/")}
            >
              <AppLogo />
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Einstellungen">
              <IconButton
                id="menu-menu-button"
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
              >
                {username === "undefined" ? (
                  <Skeleton variant="circular" width={40} height={40} />
                ) : (
                  <Avatar
                    {...stringAvatar(username)}
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                    }}
                  ></Avatar>
                )}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <ConfirmationDialog
                submitText={""}
                cancelText={"Schließen"}
                text={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ flexDirection: "column" }}>
                      <h4>Profil</h4>
                      <TextField
                        id="standard-basic"
                        disabled
                        style={{ paddingRight: "25px" }}
                        value={username}
                        label="Benutzername"
                        variant="standard"
                      />
                      <TextField
                        id="standard-basic"
                        disabled
                        style={{ width: "250px" }}
                        value={email}
                        label="E-Mail"
                        variant="standard"
                      />
                    </div>
                    <div>
                      <h4>Weitere Einstellungen</h4>
                      <ThemeSwitcher label={"Dark Mode"}></ThemeSwitcher>
                    </div>
                  </div>
                }
                onClick={handleCloseUserMenu}
                RemoveButton={
                  <MenuItem>
                    <ListItemText>Profil</ListItemText>
                  </MenuItem>
                }
                titleText={"Einstellungen"}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                callback={async () => {}}
              ></ConfirmationDialog>
              <MenuItem
                id="logout-menuitem"
                onClick={() => {
                  auth.signoutRedirect({
                    post_logout_redirect_uri: window.location.origin,
                  });
                  handleCloseNavMenu();
                }}
              >
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate("/add-company")}>
                <ListItemText>Neues Unternehmen anlegen</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </div>
    </AppBar>
  );
};

export default ProjectNavigationBar;
