import { createTheme } from "@mui/material";
import { atom, selector } from "recoil";

const muiDarkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#00a2aa",
    },
    secondary: {
      main: "#fca17d",
    },
  },
});

const muiLightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#006165",
    },
    secondary: {
      main: "#fca17d",
    },
    background: {
      default: "#333",
    },
  },
});

export const isDarkThemeState = atom<boolean>({
  key: "isDarkThemeState",
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newState) => {
        localStorage.setItem("dark-theme", newState.toString());
      });
    },
  ],
  default: localStorage.getItem("dark-theme") === "true",
});

export const themeState = selector({
  key: "themeState",
  get: ({ get }) => {
    const isDarkTheme = get(isDarkThemeState);
    return isDarkTheme ? muiDarkTheme : muiLightTheme;
  },
});
