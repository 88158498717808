import { AxiosError, AxiosInstance } from "axios";
import {
  FileConfirmationInfo,
  FileConfirmationResponse,
} from "../models/file-confirmation-response";
import { ProjectFile } from "../models/project-query-response";
import { axiosInstance } from "../Shell";

export default class FileConfirmationService {
  private readonly _axios: AxiosInstance;
  constructor() {
    this._axios = axiosInstance;
  }

  async confirmFile(file: ProjectFile) {
    try {
      const response = await this._axios.post("/upload-confirmation", {
        fileId: file.id,
      });
      if (response.status === 200) {
        return;
      }

      if (response.status === 401) {
        throw new Error(
          `Fehler bei der Lesebestätigung!\nError code: ${response.status}`
        );
      }

      throw new Error(
        `Fehler bei der Lesebestätigung!\nError code: ${response.status}\n${
          response.data?.message ?? response
        }`
      );
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        throw new Error(
          `Fehler bei der Lesebestätigung!\n${
            axiosError.response.data?.message ?? axiosError
          }`
        );
      }
      throw new Error(`Fehler bei der Lesebestätigung!\n${error}`);
    }
  }

  async getConfirmedFiles(fileId: string): Promise<FileConfirmationInfo> {
    try {
      const response = await this._axios.get<FileConfirmationResponse>(
        `/upload-confirmation/${fileId}`
      );
      if (response.status === 200) {
        return response.data.message;
      }

      if (response.status === 401) {
        throw new Error(
          `Fehler bei der Lesebestätigung!\nError code: ${response.status}`
        );
      }

      throw new Error(
        `Fehler bei der Lesebestätigung!\nError code: ${response.status}\n${
          response.data?.message ?? response
        }`
      );
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        throw new Error(
          `Fehler bei der Lesebestätigung!\n${
            axiosError.response.data?.message ?? axiosError
          }`
        );
      }
      throw new Error(`Fehler bei der Lesebestätigung!\n${error}`);
    }
  }
}
