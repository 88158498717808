import { AxiosInstance } from "axios";
import { ApiResponse } from "../models/api-response";
import { Company } from "../models/company";
import CompanyEmployee from "../models/companyEmployee";
import { axiosInstance } from "../Shell";
import { handleAxiosError } from "../utils/axios-error-handling";

export class CompanyService {
  private readonly _axios: AxiosInstance;
  constructor() {
    this._axios = axiosInstance;
  }

  async addCompany(name: string): Promise<Company> {
    try {
      const response = await this._axios.post<ApiResponse<Company>>(
        "/company",
        { name: name }
      );
      return response.data.message;
    } catch (error) {
      handleAxiosError(error, "Fehler beim Anlegen des Unternehmens");
      throw error;
    }
  }

  async getCompanies(): Promise<Company[]> {
    try {
      const response = await this._axios.get("/company");
      return Object.values(response.data?.message);
    } catch (error) {
      handleAxiosError(error, "Fehler beim Abfragen der Unternehmen");
      throw error;
    }
  }

  async getCompany(id: string): Promise<Company> {
    try {
      const response = await this._axios.get(`company/${id}`);
      return response.data?.message;
    } catch (error) {
      handleAxiosError(error, "Fehler beim Abfragen des Unternehmens!");
      throw error;
    }
  }

  async getEmployeesForCompany(companyId: string): Promise<CompanyEmployee[]> {
    try {
      const response = await this._axios.get(`/company-employee/${companyId}`);
      return response.data?.message;
    } catch (error) {
      handleAxiosError(error, "Fehler beim Abfragen der Mitarbeiter");
      throw error;
    }
  }

  async addEmployeeToCompany(email: string, company: Company): Promise<void> {
    try {
      await this._axios.post(`/company-employee`, {
        company_id: company.id,
        email: email,
      });
    } catch (error) {
      handleAxiosError(error, "Fehler beim Hinzufügen des Mitarbeiters");
      throw error;
    }
  }

  async removeEmployeeFromCompany(
    employee: CompanyEmployee,
    company: Company
  ): Promise<void> {
    try {
      await this._axios.delete(`/company-employee/${employee.id}`, {
        data: {
          company_id: company.id,
        },
      });
    } catch (error) {
      handleAxiosError(error, "Fehler beim Löschen des Mitarbeiters");
      throw error;
    }
  }

  async updateCompanyName(
    companyId: string,
    companyName: string
  ): Promise<void> {
    try {
      await this._axios.put(`/company/${companyId}`, {
        companyname: companyName,
      });
    } catch (error) {
      handleAxiosError(error, "Fehler beim Bearbeiten des Unternehmens");
      throw error;
    }
  }
}
