import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Alerts from "../components/alerts";
import ProjectNavigationBar from "../components/project-navigation-bar";
import UploadPreview from "../components/upload-preview";
import { ProjectFile } from "../models/project-query-response";
import FileConfirmationService from "../services/file-confirmation.service";
import FileDownloadService from "../services/file-download.service";
import { selectedProjectState } from "../state/selected-project-state";

export interface ConfirmFileProps {
  file: ProjectFile;
}

const ConfirmFile = (props: ConfirmFileProps) => {
  const selectedProject = useRecoilValue(selectedProjectState);
  const setSelectedProject = useSetRecoilState(selectedProjectState);

  const [fileUrl, setFileUrl] = useState<string>("");
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const [lastPdfPageVisited, setLastPdfPageVisited] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fileDownloadService = new FileDownloadService();
    if (selectedProject) {
      fileDownloadService
        .loadRemoteFile(props.file, selectedProject)
        .then((url) => setFileUrl(url));
    }
  }, [selectedProject, props.file]);

  const confirmFile = async () => {
    if (!selectedProject) {
      return;
    }
    const fileConfirmationService = new FileConfirmationService();
    try {
      await fileConfirmationService.confirmFile(props.file);
      setSelectedProject({
        ...selectedProject,
        ownFile: selectedProject.ownFile.map((x: ProjectFile) =>
          x.id === props.file.id ? { ...x, confirmed: "1" } : x
        ),
      });
      enqueueSnackbar("Datei erfolgreich bestätigt", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Paper
      square
      style={{
        height: "100vh",
      }}
    >
      <ProjectNavigationBar showMenu={false}></ProjectNavigationBar>
      <div className="alerts">
        <Alerts></Alerts>
      </div>
      <Grid container height="calc(100% - 60px)">
        <Grid item sm={12} height="100%">
          <Container
            style={{
              height: "80%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
            maxWidth="sm"
          >
            <UploadPreview
              fileUrl={fileUrl}
              fileMimeType={props.file.mimecontenttype}
              onLastPageVisit={() => {
                return setLastPdfPageVisited(true);
              }}
            ></UploadPreview>
          </Container>
          <Container
            maxWidth="sm"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Tooltip
              children={
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isConfirmed}
                      disabled={
                        props.file.mimecontenttype === "application/pdf" &&
                        !lastPdfPageVisited
                      }
                      onChange={(_, checked) => {
                        setIsConfirmed(checked);
                      }}
                    ></Checkbox>
                  }
                  label="Bitte bestätige, dass du den Inhalt gelesen hast"
                />
              }
              title={
                props.file.mimecontenttype === "application/pdf" &&
                !lastPdfPageVisited
                  ? "Scroll bis zum Ende der Datei, um zu bestätigen, dass du den Inhalt gelesen hast!"
                  : ""
              }
            ></Tooltip>

            <Button
              variant="contained"
              disabled={!isConfirmed}
              onClick={confirmFile}
            >
              Bestätigen
            </Button>
          </Container>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ConfirmFile;
