import { Alert } from "@mui/material";
import { useAlert } from "../effects/use-alert";

const Alerts = () => {
    const { alerts } = useAlert();
    return (
        <div>
            {
                alerts.map((alert, index) => {
                    return <Alert key={index} severity={alert.severity}>{alert.text}</Alert>
                })
            }
        </div>
    )
}

export default Alerts;