import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export type ConfirmationDialogProps = {
  submitText: string;
  cancelText: string;
  titleText: string;
  text?: React.ReactNode;
  RemoveButton?: React.ReactNode;
  callback?: () => void;
  onClick?: () => void;
};

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    props.onClick?.();
    setOpen(true);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{props.RemoveButton}</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.titleText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{props.cancelText}</Button>
          {props.submitText.length !== 0 ? (
            <Button
              onClick={(event) => {
                event.stopPropagation();
                if (props.callback) {
                  props.callback();
                }
              }}
              autoFocus
            >
              {props.submitText}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
