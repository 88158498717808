import { atom, selector } from "recoil";
import { Company } from "../models/company";
import { CompanyService } from "../services/company.service";
import LogService from "../services/log.service";

export const allCompaniesState = selector<Company[]>({
  key: "allCompaniesState",
  get: async () => {
    const companyService = new CompanyService();
    const logService = new LogService();

    try {
      return await companyService.getCompanies();
    } catch (error) {
      logService.error(error);
      console.error(`allCompaniesState -> get companies ERROR: \n${error}`);
      throw error;
    }
  },
});

export const companyState = atom<Company[]>({
  key: "companyState",
  default: allCompaniesState,
});
