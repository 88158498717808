import "./App.css";
import { RecoilRoot } from "recoil";
import Shell from "./Shell";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAt,
  faChevronLeft,
  faChevronRight,
  faEllipsisV,
  faFileDownload,
  faFileUpload,
  faRotateRight,
  faUserCheck,
  faUserMinus,
  faUserSlash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faFilePdf, faFileImage } from "@fortawesome/free-regular-svg-icons";
import ErrorBoundary from "./components/error-boundary";
import { AuthProvider } from "react-oidc-context";
import { User } from "oidc-client-ts";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { SnackbarProvider } from "notistack";

library.add(
  faChevronLeft,
  faChevronRight,
  faAt,
  faXmark,
  faFilePdf,
  faFileImage,
  faFileUpload,
  faUserCheck,
  faUserMinus,
  faUserSlash,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faFileDownload,
  faEllipsisV,
  faRotateRight
);

function App() {
  return (
    <AuthProvider
      client_id="test-mach-web"
      redirect_uri={window.location.origin}
      authority="https://auth.mach.app/auth/realms/mach-app"
      revokeTokensOnSignout={true}
      onSigninCallback={(_user: User | void) => {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }}
    >
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="de">
        <RecoilRoot>
          <ErrorBoundary>
            <SnackbarProvider maxSnack={3}>
              <Shell></Shell>
            </SnackbarProvider>
          </ErrorBoundary>
        </RecoilRoot>
      </LocalizationProvider>
    </AuthProvider>
  );
}

export default App;
