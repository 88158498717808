import { atom, DefaultValue, selector } from "recoil";
import { Email, Project, ResponseProject } from "../models/project-query-response";
import { projectState } from "./project-state";

export const selectedProjectIdState = atom<string | undefined>({
  default: undefined,
  key: "selectedProjectId",
});

export const selectedProjectState = selector<ResponseProject | undefined>({
  key: "selectedProjectState",
  get: ({ get }) => {
    const selectedId = get(selectedProjectIdState);
    const projects = get(projectState);
    if (!selectedId) {
      return undefined;
    }
    const selectedProject = projects.find((x) => x.id === selectedId);
    return selectedProject;
  },
  set: ({ get, set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return;
    }
    set(selectedProjectIdState, newValue?.id);
    const projects = get(projectState);
    const project = projects.find((x) => x.id === newValue?.id);
    if (project) {
      set(
        projectState,
        projects.map((x) => (x.id === newValue?.id ? newValue : x))
      );
    }
  },
});
