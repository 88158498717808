import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { Alert, alertState } from "../state/alert-state";

export function useAlert() {
  const [alerts, setAlert] = useRecoilState<Alert[]>(alertState);
  const showAlert = (alert: Alert) => {
    setAlert([...alerts, alert]);
  };
  const hideAlert = (alert: Alert) => {
    setAlert(list => list.filter((x) => x.id !== alert.id));
  };

  useEffect(() => {
    if (!alerts || alerts.length === 0) {
      return;
    }
    setTimeout(() => {
      hideAlert(alerts[alerts.length - 1]);
    }, alerts[alerts.length - 1].timeout);
  }, [JSON.stringify(alerts)]);
  return { alerts, showAlert, hideAlert };
}
