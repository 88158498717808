import {AxiosInstance} from "axios";
import {GroupCreation} from "../models/group-creation";
import {
  ProjectsQueryResponse,
  ResponseProject,
} from "../models/project-query-response";
import {axiosInstance} from "../Shell";
import {handleAxiosError} from "../utils/axios-error-handling";

export class ProjectService {
  private readonly _axios: AxiosInstance;

  constructor() {
    this._axios = axiosInstance;
  }

  async addProject(
      name: string,
      groups: GroupCreation[],
    companyId: string
  ): Promise<string> {
    try {
      const response = await this._axios.post("/projects", {
        projectname: name,
        company_id: companyId,
        groups: groups.map((x) => {
          const mails = x.emails;
          return {
            ...x,
            emails: mails.map((x) => ({
              email: x.adress,
              active: x.active,
              managementRight: x.managementRight
            })),
          };
        }),
      });
      return response.data.message[0].id;
    } catch (error) {
      handleAxiosError(error, "Fehler beim Hinzufügen des Projekts");
      throw error;
    }
  }

  async updateProject(
    id: string,
    name: string,
    groups: GroupCreation[],
    companyId: string
  ): Promise<void> {
    try {
      await this._axios.put(`/projects/${id}`, {
        id: id,
        projectname: name,
        company_id: companyId,
        groups: groups.map((x) => {
          const mails = x.emails;
          return {
            ...x,
            emails: mails.map((x) => ({
              email: x.adress,
              active: x.active,
              managementRight: x.managementRight
            })),
          };
        }),
      });
    } catch (error) {
      handleAxiosError(error, "Fehler beim Updaten des Projekts");
      throw error;
    }
  }

  async delete(id: string): Promise<void> {
    try {
      await this._axios.delete(`/projects/${id}`);
    } catch (error) {
      handleAxiosError(error, "Fehler beim Löschen des Projekts");
      throw error;
    }
  }

  async getProjects(): Promise<ResponseProject[]> {
    try {
      const response = await this._axios.get<ProjectsQueryResponse>(
        "/projects"
      );
      return response.data.message;
    } catch (error) {
      handleAxiosError(error, "Fehler beim Abfragen der Projekte");
      throw error;
    }
  }
}
