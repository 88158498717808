import { atom } from "recoil";

let _id = 0;

export class Alert {
    opened: boolean;
    id: number;
    constructor(public timeout: number, public text: string, public severity: "error" | "warning" | "info" | "success") {
        this.opened = false;
        this.id = _id++;
    }

}

export const alertState = atom<Alert[]>({
  key: "alertState",
  default: [],
});
