import { LinearProgress } from "@mui/material";
import AppLogo from "./app-logo";

const LoadingIndicator = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <AppLogo></AppLogo>
        <LinearProgress
          color="primary"
          sx={{
            width: "33%",
            marginTop: 2
          }}
        />
      </div>
    </div>
  );
};

export default LoadingIndicator;
