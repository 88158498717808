import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";

export type UploadPreviewProps = {
  fileMimeType: string;
  fileUrl: string;
  onLastPageVisit?: () => void;
};

const UploadPreview = (props: UploadPreviewProps) => {
  const [pageCount, setPageCount] = useState(0);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight;
    if (bottom) {
      props.onLastPageVisit?.();
    }
  };

  useEffect(() => {
    if (pageCount === 1) {
      props.onLastPageVisit?.();
    }
  }, [pageCount, props]);

  if (props.fileMimeType === "application/pdf") {
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          overflow: "scroll",
          height: "100%",
        }}
        onScroll={(event) => handleScroll(event)}
      >
        <Document
          file={props.fileUrl}
          onLoadSuccess={(pdf) => setPageCount(pdf.numPages)}
          loading="Lade PDF..."
        >
          {[...Array(pageCount)].map((x, index) => (
            <Page pageIndex={index} renderAnnotationLayer={false} loading=""></Page>
          ))}
        </Document>
        <Card
          style={{
            position: "absolute",
            bottom: "10%",
            left: "50%",
            transform: "translate(-50%,0%)",
          }}
        ></Card>
      </div>
    );
  }
  return (
    <img
      style={{
        maxHeight: "100%",
        maxWidth: "100%",
      }}
      src={props.fileUrl}
      alt="preview"
    ></img>
  );
};

export default UploadPreview;
