import React from "react";
import { useRecoilValue } from "recoil";
import { themeState } from "../state/theme-state";

import "./app-logo.css";


const AppLogo = () => {
  const theme = useRecoilValue(themeState);
  return (
    <div className="app-logo-container" >
      <svg
        id="Ebene_1"
        data-name="Ebene 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 313 62"
        fill={theme.palette.primary.main}
      >
        <polygon points="0 60.5 0 1.5 13 1.5 32 39.5 51 0.5 64 0.5 64 60.5 54 60.5 53 18.5 35 54.5 28 54.5 10 17.5 10 60.5 0 60.5" />
        <polygon points="80 60.5 106.5 1 118.09 1 144.5 61 132.5 61 112 13.5 92 60.5 80 60.5" />
        <path d="M196.76,95" transform="translate(-42.5 -64)" />
        <polygon points="280 0.5 269 0.5 269 26.5 239 26.5 239 0.5 227 0.5 227 60.5 239 60.5 239 36.5 269 36.5 269 60.5 280 60.5 280 0.5" />
        <polygon points="302 1.5 304 41.5 310.09 41.5 312.74 1.5 302 1.5" />
        <circle cx="307" cy="55.5" r="6" />
        <path
          d="M251.13,74.35m.12,41.15M248,78l3.37-4.65A35.29,35.29,0,0,0,228,64a31,31,0,0,0,0,62c9.26,0,17.82-5.06,23.5-11.5l-6.7-6.58a21.13,21.13,0,0,1-16.3,7.58,20.51,20.51,0,1,1,0-41A22.24,22.24,0,0,1,245,82Zm-3.42,3.81"
          transform="translate(-42.5 -64)"
        />
        <path d="M244.58,81.81" transform="translate(-42.5 -64)" />
      </svg>
    </div>
  );
};

export default AppLogo;
