import {AxiosInstance} from "axios";
import {Project, ProjectFile} from "../models/project-query-response";
import {axiosInstance} from "../Shell";
import * as OpenPGP from "openpgp";

export default class FileDownloadService {
    private readonly _axios: AxiosInstance;

    constructor() {
        this._axios = axiosInstance;
    }

    async loadRemoteFile(file: ProjectFile, project: Project): Promise<string> {
        const response = await axiosInstance.get(`/uploads/${file.id}`, {
            responseType: "arraybuffer",
          });

          const pgpMessage = await OpenPGP.readMessage({
            binaryMessage: new Uint8Array(response?.data),
          });
      
          const { data: decrypted } = await OpenPGP.decrypt({
            message: pgpMessage,
            passwords: [project.secretfilecryptkey],
            format: "binary",
          });
      
          const blob = new Blob([decrypted as Uint8Array], {
            type: file.mimecontenttype,
          });
      
          return window.URL.createObjectURL(blob);
    }
}