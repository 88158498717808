import { BrowserRouter, Route, Routes } from "react-router-dom";

import React, { Suspense } from "react";
import { useRecoilValueLoadable } from "recoil";
import { companyState } from "../state/company-state";
import { selectedProjectState } from "../state/selected-project-state";
import ConfirmFile from "../pages/confirm-file";
import LoadingIndicator from "../components/loading-indicator";
import { axiosInstance } from "../Shell";
import { useAuth } from "react-oidc-context";
import { DisplayQrCode } from "../pages/qr-code";

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  if (auth.isAuthenticated) {
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${auth.user?.access_token}`;
    return <>{children}</>;
  }

  if (!auth.error && auth.activeNavigator === undefined && !auth.isLoading) {
    const expanded = localStorage.getItem('menu-expanded');
    localStorage.clear();
    if(expanded !== null) {
      localStorage.setItem('menu-expanded', expanded);
    }
    console.info("Initiating signinRedirect()");
    auth.signinRedirect();
  }

  return <LoadingIndicator></LoadingIndicator>;
}

function RequireCompany({ children }: { children: JSX.Element }) {
  const companies = useRecoilValueLoadable(companyState);
  switch (companies.state) {
    case "loading":
      return <LoadingIndicator />;
    case "hasValue":
      return companies.contents.length === 0 ? (
        <EditCompany></EditCompany>
      ) : (
        children
      );
    case "hasError":
      return <div>Error while loading companies</div>;
  }
}

function RequireReadFile({ children }: { children: JSX.Element }) {
  const selectedProject = useRecoilValueLoadable(selectedProjectState);
  switch (selectedProject.state) {
    case "hasError":
      return <div>Error while checking file read requirements</div>;
    case "loading":
      return <LoadingIndicator />;
    case "hasValue": {
      const filesToConfirm = selectedProject?.contents?.ownFile?.filter(
        (x) => x.confirmationrequired === "1" && x.confirmed !== "1"
      );
      if (filesToConfirm && filesToConfirm.length > 0) {
        return <ConfirmFile file={filesToConfirm[0]}></ConfirmFile>;
      }

      return children;
    }
  }
}

const Home = React.lazy(() => import("../pages/home"));
const ProjectDetail = React.lazy(() => import("../components/project-detail"));
const FileConfirmations = React.lazy(
  () => import("../pages/file-confirmations")
);
const AddOrEditProject = React.lazy(
  () => import("../pages/add-project/add-project")
);
const EditCompany = React.lazy(
  () => import("../pages/edit-company/edit-company")
);

export const AppRouter = () => {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <RequireAuth>
                  <Home></Home>
                </RequireAuth>
              </Suspense>
            }
          />
          <Route
            path="/:id"
            element={
              <RequireAuth>
                <RequireReadFile>
                  <Home></Home>
                </RequireReadFile>
              </RequireAuth>
            }
          >
            <Route path="/:id" element={<ProjectDetail />} />
            <Route path="/:id/details" element={<ProjectDetail />} />
            <Route path="/:id/file/:fileId" element={<FileConfirmations />} />
          </Route>
          <Route
            path="/add-project"
            element={
              <RequireAuth>
                <RequireCompany>
                  <AddOrEditProject />
                </RequireCompany>
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/add-company"
            element={
              <RequireAuth>
                <EditCompany />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/company/:id/edit"
            element={
              <RequireAuth>
                <EditCompany />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/:id/edit-project"
            element={
              <RequireAuth>
                <RequireCompany>
                  <AddOrEditProject />
                </RequireCompany>
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/:id/qr-code"
            element={
              <RequireAuth>
                <DisplayQrCode />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};
