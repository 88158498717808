import {atom, selector} from "recoil";
import {ResponseProject, Role} from "../models/project-query-response";
import {ProjectService} from "../services/project.service";

const groupBy = <T>(array: T[], predicate: (v: T) => string) =>
    array.reduce((acc, value) => {
        (acc[predicate(value)] ||= []).push(value);
        return acc;
    }, {} as { [key: string]: T[] });

export const groupedProjectsState = selector<{ [key: string]: ResponseProject[] }>({
    key: "groupedProjectsState",
    get: ({get}) => {
        const projects = get(projectState);
        return groupBy(projects, (p) => p.company_name);
    },
});

function mapEmailRights(projects: ResponseProject[]) {
    projects.forEach(project => {
        const roles = project.role;

        project.ownGroup?.forEach(group => {
            const emails = group.ownEmail;

            emails?.forEach(email => {
                const matchingRole = roles.find(x => x.id === email.role_id) ?? {name: "user"} as Role;

                email.managementRight = matchingRole.name === "project_leader" || matchingRole.name === "manager";
            })
        });
    })
}

export const allProjectsState = selector<ResponseProject[]>({
    key: "allProjectsState",
    get: async () => {
        const projectService = new ProjectService();
        try {
            const projects = await projectService.getProjects();

            mapEmailRights(projects);

            return projects;
        } catch (error) {
            console.error(`allProjectsState -> get projects ERROR: \n${error}`);
            throw error;
        }
    },
});

export const projectState = atom<ResponseProject[]>({
    key: "projectState",
    default: allProjectsState,
});
